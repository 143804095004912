jQuery(document).ready(function ($) {

  //Nav Functionality - Start
  $('.nav-primary .nav-toggle').click(function () {
    $('.nav-primary .ul1').slideToggle('slow');
    $('.nav-toggle .fa-bars').toggle();
    $('.nav-toggle .fa-times').toggle();
    $('header').toggleClass('fixed');
    $('body').toggleClass('fixed');
  });

  $('.nav-primary .nav-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.nav-primary .ul1').slideToggle('slow');
      $('.nav-toggle .fa-bars').toggle();
      $('.nav-toggle .fa-times').toggle();
      $('header').toggleClass('fixed');
      $('body').toggleClass('fixed');
    }
  });

  // primary nav
  $('.nav-primary').accessibleNav({
    spans: 'hide',
    level2position: 'vertical-bottom',
    level3position: 'horizontal-right'
  });
  //Nav Functionality - End

  //Search Functionality - Start
  $('.search-toggle').click(function () {
    $('.search-box').slideDown('slow');
    $('.search-box input').first().focus();
    $('.search-box .fa-times').click(function () {
      $('.search-box').slideUp('slow');
    });
    // find focusable items
    var searchFocusable = $('.search-box span.fas.fa-times, .search-box .search-container a.btn-search, .search-box .search-container input');
    var searchFocusableFirst = searchFocusable.first();
    var searchFocusableLast = searchFocusable.last();
    searchFocusableLast.on('keydown', function (e) {
      if ((e.which === 9 && !e.shiftKey)) {
        e.preventDefault();
        searchFocusableFirst.focus();
      }
    });
    searchFocusableFirst.on('keydown', function (e) {
      if ((e.which === 9 && e.shiftKey)) {
        e.preventDefault();
        searchFocusableLast.focus();
      }
    });
  });

  $('.search-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.search-box').slideDown('slow');
      $('.search-box input').first().focus();
      $('.search-box .fa-times').on('keypress', function (e) {
        if (e.which === 13) {
          $('.search-box').slideUp('slow');
        }
      });
      // find focusable items
      var searchFocusable = $('.search-box span.fas.fa-times, .search-box .search-container a.btn-search, .search-box .search-container input');
      var searchFocusableFirst = searchFocusable.first();
      var searchFocusableLast = searchFocusable.last();
      searchFocusableLast.on('keydown', function (e) {
        if ((e.which === 9 && !e.shiftKey)) {
          e.preventDefault();
          searchFocusableFirst.focus();
        }
      });
      searchFocusableFirst.on('keydown', function (e) {
        if ((e.which === 9 && e.shiftKey)) {
          e.preventDefault();
          searchFocusableLast.focus();
        }
      });
    }
  });
  //Search Functionality - End

  //Scroll to Top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
      $(".scroll-to-top").css("opacity", "1");
    } else {
      $(".scroll-to-top").css("opacity", "0");
    }
  });
  $(".scroll-to-top").click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  // $(window).scroll(function () {
  //   $(".scroll-to-top").removeClass("move");
  //   if ($(window).scrollTop() + $(window).height() > ($(document).height() - 100)) {
  //     $(".scroll-to-top").addClass("move");
  //   }
  // });
  //Scroll to Top END

  //Highlighted Box icon - hide image if nothing uploaded
  $(".highlighted-boxes .box .icon img[src$='{{box-icon}}']").css('display', 'none');

});